import { LosseLink, type LosseLinkProps } from '@ubo/losse-sjedel'

export default function CustomLosseLink({ to, ...rest }: LosseLinkProps & { to?: string }) {
  if (to?.includes('watersport')) {
    return (
      <a href={to} {...rest}>
        {/* @ts-ignore */}
        {rest.children}
      </a>
    )
  }

  if (to === undefined) {
    return <LosseLink {...rest} />
  }

  return <LosseLink to={to} {...rest} />
}
